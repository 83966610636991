import { Container } from "./styles";
import emailIcon from "../../assets/email-icon.svg";
import phoneIcon from "../../assets/phone-icon.svg"
import { Form } from "../Form/Form";


export function Contact(){

  return(
    <Container id="contact">
      <header>
        <h2>Contact Me</h2>
        <p>If you've seen my potential or want to talk to me, don't hesitate to send me a message.</p>
      </header>
      <div className="contacts">
        <a  href="mailto:denislistiadi24@gmail.com" className="button">
          <img src={emailIcon} alt="Email" />
          <p>denislistiadi24@gmail.com</p>
        </a>
        <a href="tel:+6285225186216" className="button">
          <img src={phoneIcon} alt="Phone" />
          <p>(62) 852-2518-6216</p>
        </a>  
      </div>
      <Form></Form>
    </Container>
  )
}