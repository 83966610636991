import { Container } from "./styles";

import reactIcon from "../../assets/react-icon.svg";
import linkedinIcon from "../../assets/linkedin.png";
import githubIcon from "../../assets/github.png";
// import facebookIcon from "../../assets/facebook.png";
import twitterIcon from "../../assets/twitter.png";
// import instagramIcon from '../../assets/instagram.png'
// import discordIcon from '../../assets/discord.png'

export function Footer() {
  return (
    <Container className="footer">
      <a href="/" className="logo">
        <span>Denis </span>
        <span>Listiadi</span>
      </a>
      <div>
        <p>
          This website was made with <img src={reactIcon} alt="React" />{" "}
          <span> by Denis Listiadi</span>
        </p>
      </div>

      <div className="social-media">
        {/* <a
          href="https://www.facebook.com/denis.id/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebookIcon} alt="Facebook" />
        </a> */}

        <a
          href="https://twitter.com/denis_listiadi/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={twitterIcon} alt="Twitter" width={120} />
        </a>

        <a
          href="https://www.linkedin.com/in/denis-listiadi/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedinIcon} alt="Linkedin" />
        </a>

        <a
          href="https://github.com/denislistiadi"
          target="_blank"
          rel="noreferrer"
        >
          <img src={githubIcon} alt="GitHub" />
        </a>

        {/* <a
          href="https://www.instagram.com/tulio_joaob/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagramIcon} alt="Instagram" />
        </a> */}

        {/* <a
          href="https://discord.com/users/426120432991862784"
          target="_blank"
          rel="noreferrer"
        >
          <img src={discordIcon} alt="Discord" />
        </a> */}
      </div>
    </Container>
  );
}
